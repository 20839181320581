<template>
  <div :class="['auth', {'column': step===3}]">
    <div class="auth__card card">
      <img class="card__logo" src="@/assets/svg/logo/red.svg" alt=""/>
      <h1 class="card__text">{{ $t('registration') }}</h1>
      <auth-first-step v-if="step === 1" @success="step = 2"/>
      <auth-in-second-step v-if="step === 2" @success="step = 3"/>
      <user-data v-if="step === 3"/>
    </div>
    <div class="auth__navbar" v-if="step === 3">
      <a href="https://xn--b1add.xn--e1aabhcpqfgk.xn--j1agca3a5c.xn--p1ai/public/upload/document/undefined_2909546b04.pdf" target="_blank">{{ $t('instructions-for-business') }}</a>
      <a href="https://xn--b1add.xn--e1aabhcpqfgk.xn--j1agca3a5c.xn--p1ai/public/upload/document/undefined_74581fce27.pdf" target="_blank">{{ $t('instructions-for-lawyers') }}</a>
      <a href="https://xn--b1add.xn--e1aabhcpqfgk.xn--j1agca3a5c.xn--p1ai/public/upload/document/undefined_078349c58d.pdf" target="_blank">{{ $t('instructions-for-inspectors') }}</a>
      <a href="https://xn--b1add.xn--e1aabhcpqfgk.xn--j1agca3a5c.xn--p1ai/public/upload/document/undefined_16d99ed794.pdf" target="_blank">{{ $t('instructions-for-authorities') }}</a>
    </div>
    <ui-menu v-model="visibleSettings" v-if="step === 3">
      <template #activator>
        <p class="auth__navbar-text">{{ $t('instructions') }} <img src="@/assets/svg/arrows/select-red.svg" alt="" /></p>
      </template>
      <template #body>
        <div class="sort">
          <a href="https://xn--b1add.xn--e1aabhcpqfgk.xn--j1agca3a5c.xn--p1ai/public/upload/document/undefined_2909546b04.pdf" target="_blank">{{ $t('instructions-for-business') }}</a>
          <a href="https://xn--b1add.xn--e1aabhcpqfgk.xn--j1agca3a5c.xn--p1ai/public/upload/document/undefined_74581fce27.pdf" target="_blank">{{ $t('instructions-for-lawyers') }}</a>
          <a href="https://xn--b1add.xn--e1aabhcpqfgk.xn--j1agca3a5c.xn--p1ai/public/upload/document/undefined_078349c58d.pdf" target="_blank">{{ $t('instructions-for-inspectors') }}</a>
          <a href="https://xn--b1add.xn--e1aabhcpqfgk.xn--j1agca3a5c.xn--p1ai/public/upload/document/undefined_16d99ed794.pdf" target="_blank">{{ $t('instructions-for-authorities') }}</a>
        </div>
      </template>
    </ui-menu>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import UiMenu from "@/components/ui/UiMenu.vue";

export default {
  name: 'AuthContent',
  components: {
    UiMenu,
    AuthFirstStep: () => import('@/components/template/auth/AuthFirstStep'),
    AuthInSecondStep: () => import('@/components/template/auth/AuthInSecondStep'),
    UserData: () => import('@/components/template/auth/UserData')
  },

  data() {
    return {
      step: 1,
      visibleSettings: false
    }
  },

  methods: {
    ...mapMutations([
      'setAuth',
      'clearAuthForm',
    ]),
  },

  mounted() {
    this.setAuth(false)
  },

  destroyed() {
    this.clearAuthForm()
  }
}


</script>

<style lang="scss" scoped>
.auth {
  min-height: 100vh;
  background: #F5F5F5;
  padding: 150px 20px 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  @media (max-width: 768px) {
    padding: 110px 20px 30px;
  }

  &.column {
    @media (max-width: 1100px) {
      flex-direction: column-reverse;
      align-items: center;
      gap: 30px;
    }
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    left: 50px;

    @media (max-width: 1100px) {
      display: none;
    }

    &-text {
      display: none;
      gap: 10px;

      @media (max-width: 1100px) {
        display: flex;
        font-weight: 400;
        font-size: 14px;
        line-height: 11px;
        color: #CE2121;
      }
    }
  }
}

.card {
  max-width: 434px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 20px;
  padding: 30px 34px 40px;

  @media (max-width: 768px) {
    padding: 34px 20px 27px 20px;
  }

  &__logo {
    margin-bottom: 30px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #000000;
  }
}
::v-deep input {
  font-family: "Gotham Pro Regular";
}
</style>
